import React, { useState } from "react";
import Select, { components } from "react-select";


const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0)
    valueLabel += props.selectProps.getOptionLabel(values[0]);
  if (values.length > 1) valueLabel += ` & ${values.length - 1} more`;

  // Keep standard placeholder and input from react-select
  var childsToRender = React.Children.toArray(children).filter(
    (child) =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childsToRender}
    </components.ValueContainer>
  );
};

const Filter = (props) => {
  const [inputValue, setInputValue] = useState([]);
  return (
    <div className="col pb-3">
      <Select
        closeMenuOnSelect={false}
        components={{ ValueContainer }}
        isMulti
        isClearable={true}
        noOptionsMessage={() => null}
        placeholder={props.title}
        value={props.value}
        options={props.options}
        onMenuClose={() => props.onChange(props.filter, inputValue)}
        onChange={setInputValue}
        maxMenuHeight={250}
      />
    </div>
  );
};
export default Filter;
