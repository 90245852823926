const getApplicationUrl = (type) => {
  const origin = window.location.origin;
  if (origin === "http://localhost:3000") {
    if (type === "api") {
      return "http://localhost:4000";
    } else {
      return "http://localhost:3000";
    }
  } else if (origin === "https://qa-link-reports.henselphelps.io") {
    if (type === "api") {
      return "https://qa-link-reports-api.henselphelps.io";
    } else {
      return "https://qa-link-reports.henselphelps.io";
    }
  } else {
    if (type === "api") {
      return "https://link-reports-api.henselphelps.io";
    } else {
      return "https://link-reports.henselphelps.io";
    }
  }
};

export default getApplicationUrl;
