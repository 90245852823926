import React from "react";
import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles = {
  link: { textDecoration: "none" },
  card: { opacity: 0.75 },
  cardTitle: {
    fontSize: "2rem",
    textDecoration: "underline",
    textDecorationColor: "#f2a900",
    color: "#a6192e",
    fontFamily: "Helvetica LT std light",
    display: "inline",
  },
  cardText: {
    fontSize: "1.25rem",
    color: "#121212",
    fontFamily: "Helvetica LT std light",
  },
};
const Home = (props) => {
  return (
    <div className="container-fluid">
      <div className="bg resize-bg-sm">
        <br />
        <div className="row pb-3 pt-3">
          <div className="col">
            <Image
              src={require("../logoTagline.png")}
              style={{ width: "30%", minWidth: 250 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1 p-4">
            <Link to="/course-descriptions" style={styles.link}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title
                    style={styles.cardTitle}
                    className="resize-title-sm"
                  >
                    Course Descriptions
                  </Card.Title>
                  <Card.Text style={styles.cardText} className="resize-text-sm">
                    Print PDFs of the courses available in LINK
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1 p-4">
            <Link to="/certificates" style={styles.link}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title
                    style={styles.cardTitle}
                    className="resize-title-sm"
                  >
                    Certificates
                  </Card.Title>
                  <Card.Text style={styles.cardText} className="resize-text-sm">
                    Print certificates of completion for employees in LINK
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1 p-4">
            <Link to="/learning-plans" style={styles.link}>
              <Card style={styles.card}>
                <Card.Body>
                  <Card.Title
                    style={styles.cardTitle}
                    className="resize-title-sm"
                  >
                    Learning Plans
                  </Card.Title>
                  <Card.Text style={styles.cardText} className="resize-text-sm">
                    Print CSV of a regions's Learning Plan Master spreadsheet
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
        <div className="row pt-3">
          <img
            alt=""
            src={require("../assets/logos/hpLogo.png")}
            className="d-lg-none d-xl-none"
            style={{ margin: "0 auto", width: 250 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
