import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './Config';
import { getUserDetails, getUserAvatar, getMemberGroups } from './GraphService';
import getApplicationUrl from "./appUrl";

export default function withAuthProvider(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                error: null,
                isAuthenticated: false,
                isAuthorized: false,
                user: {}
            };
            // Initialize the MSAL application object
            this.publicClientApplication = new PublicClientApplication({
                auth: {
                    clientId: config.appId,
                    redirectUri: getApplicationUrl('frontend')
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true
                }
            });
        }
        componentDidMount() {
            // If MSAL already has an account, the user
            // is already logged in
            const accounts = this.publicClientApplication.getAllAccounts();
            if (accounts && accounts.length > 0) {
                // Enhance user object with data from Graph
                this.getUserProfile();
            }
        }
        render() {
            return React.createElement(WrappedComponent, Object.assign({ error: this.state.error, isAuthenticated: this.state.isAuthenticated, isAuthorized: this.state.isAuthorized, user: this.state.user, login: () => this.login(), logout: () => this.logout(), getAccessToken: (scopes) => this.getAccessToken(scopes), setError: (message, debug) => this.setErrorMessage(message, debug) }, this.props));
        }
        async login() {
            try {
                // Login via popup
                await this.publicClientApplication.loginPopup({
                    scopes: config.scopes,
                    prompt: "select_account"
                });
                // After login, get the user's profile
                await this.getUserProfile();
            }
            catch (err) {
                this.setState({
                    isAuthenticated: false,
                    isAuthorized: false,
                    user: {},
                    error: this.normalizeError(err)
                });
            }
        }
        logout() {
            this.publicClientApplication.logout();
        }
        async getAccessToken(scopes) {
            try {
                const accounts = this.publicClientApplication
                    .getAllAccounts();
                if (accounts.length <= 0)
                    throw new Error('login_required');
                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                var silentResult = await this.publicClientApplication
                    .acquireTokenSilent({
                        scopes: scopes,
                        account: accounts[0]
                    });
                return silentResult.accessToken;
            }
            catch (err) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (this.isInteractionRequired(err)) {
                    var interactiveResult = await this.publicClientApplication
                        .acquireTokenPopup({
                            scopes: scopes
                        });
                    return interactiveResult.accessToken;
                }
                else {
                    throw err;
                }
            }
        }
        // <getUserProfileSnippet>
        async getUserProfile() {
            try {
                var accessToken = await this.getAccessToken(['user.read']);
                await this.getAccessToken(['api://5982e64d-2298-44b7-bd49-01f08c1dc2df/basic']);
                if (accessToken) {
                    // Get the user's profile from Graph
                    var user = await getUserDetails(accessToken);
                    var avatar = await getUserAvatar(accessToken);
                    var memberGroups = await getMemberGroups(accessToken)
                    this.setState({
                        isAuthenticated: true,
                        isAuthorized: memberGroups.includes('bf9c3043-cd57-4b65-9110-4980319243e8') ? true : false,
                        user: {
                            displayName: user.displayName,
                            email: user.mail || user.userPrincipalName,
                            avatar: avatar
                            // timeZone: user.mailboxSettings.timeZone,
                            // timeFormat: user.mailboxSettings.timeFormat
                        },
                        error: null
                    });
                }
            }
            catch (err) {
                console.log(this.normalizeError(err), err)
                this.setState({
                    isAuthenticated: false,
                    isAuthorized: false,
                    user: {},
                    error: this.normalizeError(err)
                });
            }
        }
        // </getUserProfileSnippet>
        setErrorMessage(message, debug) {
            this.setState({
                error: { message: message, debug: debug }
            });
        }
        normalizeError(error) {
            var normalizedError = {};
            if (typeof (error) === 'string') {
                var errParts = error.split('|');
                normalizedError = errParts.length > 1 ?
                    { message: errParts[1], debug: errParts[0] } :
                    { message: error };
            }
            else {
                normalizedError = {
                    message: error.message,
                    debug: JSON.stringify(error)
                };
            }
            return normalizedError;
        }
        isInteractionRequired(error) {
            if (!error.message || error.message.length <= 0) {
                return false;
            }
            return (error.message.indexOf('consent_required') > -1 ||
                error.message.indexOf('interaction_required') > -1 ||
                error.message.indexOf('login_required') > -1 ||
                error.message.indexOf('no_account_in_silent_request') > -1);
        }
    };
}