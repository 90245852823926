import React from "react";
import { Button } from "react-bootstrap"

const getJwttoken = () => {
    var jwttoken;
    Object.keys(window.sessionStorage).forEach(key => {
        if (key.includes('idtoken')) {
            var obj = JSON.parse(window.sessionStorage.getItem(key))
            jwttoken = obj['secret']
        }
    })
    return jwttoken
}
    
const Testing = props => {
    return (
        <div className="container">
            <br />
            <div className="row">
                <Button onClick={() => {console.log(getJwttoken(), Date.now())}}>CLICK ME</Button>
            </div>
        </div >
    );
}

export default Testing;