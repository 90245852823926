import moment from 'moment';
import { PageIterator } from '@microsoft/microsoft-graph-client';
import { config } from './Config';

var graph = require('@microsoft/microsoft-graph-client');
var data;

function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done) => {
            done(null, accessToken);
        }
    });
    return client;
}
export async function getUserDetails(accessToken) {
    const client = getAuthenticatedClient(accessToken);
    const user = await client
        .api('/me')
        .select('displayName,userPrincipalName')
        .get();
    return user;
}

export async function getUserAvatar(accessToken) {
    const client = getAuthenticatedClient(accessToken);
    try {
        await client.api('/me/photos/48x48/$value').get().then(wowIHateJS).then(res => data = res);
    }
    catch {
        return null
    }
    return data
}

export async function getMemberGroups(accessToken) {
    const client = getAuthenticatedClient(accessToken);
    const string = {
        groupIds: [
            config.groupGuid
        ]
    }
    const user = await client
        .api(`/me/checkMemberGroups`)
        .post(string);
    return user.value
}

const wowIHateJS = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result)
        }
    })
}

// </graphServiceSnippet1>
// <getUserWeekCalendarSnippet>
export async function getUserWeekCalendar(accessToken, timeZone, startDate) {
    const client = getAuthenticatedClient(accessToken);
    // Generate startDateTime and endDateTime query params
    // to display a 7-day window
    var startDateTime = startDate.format();
    var endDateTime = moment(startDate).add(7, 'day').format();
    // GET /me/calendarview?startDateTime=''&endDateTime=''
    // &$select=subject,organizer,start,end
    // &$orderby=start/dateTime
    // &$top=50
    var response = await client
        .api('/me/calendarview')
        .header("Prefer", `outlook.timezone="${timeZone}"`)
        .query({ startDateTime: startDateTime, endDateTime: endDateTime })
        .select('subject,organizer,start,end')
        .orderby('start/dateTime')
        .top(50)
        .get();
    if (response["@odata.nextLink"]) {
        // Presence of the nextLink property indicates more results are available
        // Use a page iterator to get all results
        var events = [];
        var pageIterator = new PageIterator(client, response, (event) => {
            events.push(event);
            return true;
        });
        await pageIterator.iterate();
        return events;
    }
    else {
        return response.value;
    }
}
// </getUserWeekCalendarSnippet>
// <createEventSnippet>
export async function createEvent(accessToken, newEvent) {
    const client = getAuthenticatedClient(accessToken);
    // POST /me/events
    // JSON representation of the new event is sent in the
    // request body
    return await client
        .api('/me/events')
        .post(newEvent);
}
// </createEventSnippet>