import React, { Component } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { stripHTML } from "../utils";

export default class PDFButton extends Component {
  handlePDFGenerate = () => {
    const orientation =
      this.props.pdf === "certificates" ? "landscape" : "portrait";
    const filteredData = this.props.data
      .filter((data) => this.props.rows.includes(data.id))
      .map((obj) => {
        const item = {};
        for (const [key, value] of Object.entries(obj)) {
          item[key] = typeof value === "string" ? stripHTML(value) : value;
        }
        return item;
      });

    const maxPage = filteredData.length;
    const doc = new jsPDF(orientation);
    filteredData.forEach((item, index) =>
      this.createPDFPage(doc, maxPage, item, index)
    );
    doc.deletePage(1);
    let filename;
    if (this.props.pdf === "certificates") {
      let displayName = filteredData[0].Name.replace(", ", "");
      filename = `LINK_Certificate_${displayName}.pdf`;
    } else {
      filename = `LINK_${this.props.pdf}.pdf`;
    }
    doc.save(filename);
  };

  createPDFPage(doc, maxPage, item, index) {
    if (this.props.pdf === "coursedescriptions") {
      this.getCoursesPDF(doc, maxPage, index, item);
    } else if (this.props.pdf === "certificates") {
      this.getCertificatesPDF(doc, maxPage, index, item);
    }
  }

  makeRows(doc, rowArray) {
    let accumulatedHeight;
    let maxPageHeight = 500;
    let rowHeight = 30;
    rowArray.forEach((row) => {
      let maxHeightOfColumns = [];
      let position = 15;
      row.columns.forEach((col) => {
        let splitText = doc.splitTextToSize(col.value, col.length);
        maxHeightOfColumns.push(doc.getTextDimensions(splitText).h);
      });
      accumulatedHeight =
        rowHeight + rowHeight + Math.ceil(Math.max(...maxHeightOfColumns)) + 12;
      if (accumulatedHeight > maxPageHeight) {
        doc.text(
          `Page ${doc.internal.getCurrentPageInfo().pageNumber - 1}`,
          20,
          290
        );
        doc.text(`${this.getDatetime(undefined)}`, 165, 290);
        doc.addPage();
        accumulatedHeight = 0;
        rowHeight = 15;
      }
      row.columns.forEach((col) => {
        let splitText = doc.splitTextToSize(col.value, col.length);
        maxHeightOfColumns.push(doc.getTextDimensions(splitText).h);
        doc.setFontType("bold");
        doc.text(col.name, position, rowHeight);
        doc.setFontType("normal");
        doc.text(splitText, position, rowHeight + 6);
        position += col.length + 10;
      });
      rowHeight = rowHeight + Math.ceil(Math.max(...maxHeightOfColumns)) + 12;
    });
    doc.text(
      `Page ${doc.internal.getCurrentPageInfo().pageNumber - 1}`,
      20,
      290
    );
    doc.text(`${this.getDatetime(undefined)}`, 165, 290);
  }

  getCoursesPDF(doc, maxPage, index, datum) {
    doc.addPage();
    // page width = 210, 15 margins on each size = 180 to work with
    // page height = 297, 265 after margins
    var img = new Image();
    img.src = "logo-small.png";
    doc.addImage(img, "png", 15, 9, 50, 8);
    doc.setFontType("bold");
    doc.setFontSize(20);
    let headerText = "LINK Course Description";
    let headerTextWidth =
      (doc.getStringUnitWidth(headerText) * 20) / doc.internal.scaleFactor;
    doc.text(headerText, 195 - headerTextWidth, 15);
    doc.setFontType("normal");
    doc.setFontSize(12);
    this.makeRows(doc, [
      {
        row: 1,
        columns: [
          { name: "Catalog", value: `${datum["Course Catalog"]}`, length: 30 },
          { name: "Course Name", value: `${datum["Name"]}`, length: 80 },
          { name: "Delivery", value: `${datum["Type"]}`, length: 30 },
        ],
      },
      {
        row: 2,
        columns: [
          { name: "Category", value: `${datum["Category"]}`, length: 50 },
          { name: "Duration", value: `${datum["Duration"]}`, length: 25 },
          { name: "Frequency", value: `${datum["Frequency"]}`, length: 25 },
          { name: "Learner", value: `${datum["Learners"]}`, length: 50 },
        ],
      },
      {
        row: 3,
        columns: [
          {
            name: "Selected Category",
            value: `${datum["Subcategory"]}`,
            length: 50,
          },
          {
            name: "Equivalencies",
            value: `${datum["Equivalencies"]}`,
            length: 60,
          },
          { name: "Instructors", value: `${datum["Instructors"]}`, length: 50 },
        ],
      },
      {
        row: 4,
        columns: [
          {
            name: "Description",
            value: `${datum["Description"]}`,
            length: 180,
          },
        ],
      },
      {
        row: 5,
        columns: [
          {
            name: "Learning Objectives",
            value: `${datum["Learning Objective"]}`
              .replace(/<ul>/g, "")
              .replace("<li> ", "\t\u2022 ")
              .replace(/<li> /g, "\n\t\u2022 ")
              .replace(/<li>/g, "\n\t\u2022 ")
              .replace(/<\/ul>/g, "")
              .replace(/<\/li> /g, "")
              .replace(/<\/li>/g, ""),
            length: 180,
          },
        ],
      },
      {
        row: 6,
        columns: [
          {
            name: "Documentation Requirements",
            value: `${datum["Administrator Documentation Requirements"]}`
              .replace(" 1. ", "\n\t1. ")
              .replace(" 2. ", "\n\t2. ")
              .replace(" 3. ", "\n\t3. ")
              .replace(" 4. ", "\n\t4. "),
            length: 180,
          },
        ],
      },
      {
        row: 7,
        columns: [
          {
            name: "Course Materials",
            value: `${datum["Course Materials"]}`,
            length: 180,
          },
        ],
      },
    ]);
    // // footer
    // let pageCount = doc.internal.getNumberOfPages() - 1
    // for(let i = 0; i < pageCount; i++) {
    //   doc.setPage(i);
    //   //doc.rect(18, 280, 30, 10)
    //   doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber - 1} of ${pageCount} ${i}`, 20, 290);
    //   doc.text(`${this.getDatetime(undefined)}`, 165, 290);
    // }
    if (datum["Published"] === false) {
      doc.setTextColor(255, 0, 0);
      doc.setFontType("bold");
      doc.text(
        "NOT PUBLISHED",
        doc.internal.pageSize.getWidth() / 2,
        290,
        "center"
      );
      doc.setFontType("normal");
      doc.setTextColor(0, 0, 0);
    }
  }

  getCertificatesPDF(doc, maxPage, index, datum) {
    doc.addPage();
    var img = new Image();
    img.src = "bg.jpg";
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidthWithMargins = pageWidth - 30;
    let rectHeight = 57.5;
    let textHeight = 72;
    doc.addImage(img, "jpg", 0, 0, pageWidth, pageHeight);
    //
    doc.setFontSize(40);
    doc.setFillColor(0);
    let splitCourse = doc.splitTextToSize(
      datum.Course + "",
      pageWidthWithMargins
    );
    doc.rect(
      0,
      rectHeight,
      pageWidth,
      doc.getTextDimensions(splitCourse).h + 7,
      "F"
    );
    doc.setTextColor(255, 255, 255);
    doc.text(splitCourse, pageWidth / 2, textHeight, "center");
    rectHeight += doc.getTextDimensions(splitCourse).h + 7;
    textHeight += doc.getTextDimensions(splitCourse).h + 7;
    //
    doc.setTextColor(0, 0, 0);
    doc.setFillColor(230, 230, 230);
    let splitName = doc.splitTextToSize(
      this.reorderName(datum.Name),
      pageWidthWithMargins
    );
    doc.rect(
      0,
      rectHeight,
      pageWidth,
      doc.getTextDimensions(splitName).h + 25,
      "F"
    );
    doc.text(splitName, pageWidth / 2, textHeight, "center");
    rectHeight += doc.getTextDimensions(splitName).h + 6;
    textHeight += doc.getTextDimensions(splitName).h + 4;
    //
    doc.setFontSize(24);
    doc.setFillColor(230, 230, 230);
    let splitDate = doc.splitTextToSize(
      `Completion of Course: ${this.getDatetime(datum["Completion Date"])}`,
      pageWidthWithMargins
    );
    doc.rect(
      0,
      rectHeight,
      pageWidth,
      doc.getTextDimensions(splitDate).h + 7,
      "F"
    );
    doc.text(splitDate, pageWidth / 2, textHeight, "center");
    //
    doc.setFontSize(18);
    doc.line(57, 185, 153, 185);
    doc.text("Authorized Signature", 57, 192);
    var imgLogo = new Image();
    imgLogo.src = "logo-small.png";
    doc.addImage(imgLogo, "png", 197, 182, 81.25, 13);
  }

  reorderName(name) {
    let nameArray = name.split(", ");
    let newName = nameArray[1] + " " + nameArray[0];
    return newName;
  }

  getDatetime(rawDate) {
    const d = rawDate === undefined ? new Date() : new Date(rawDate);
    let date = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
    let time =
      d.getHours() +
      ":" +
      (d.getMinutes() <= 9 ? "0" + d.getMinutes() : d.getMinutes()) +
      ":" +
      (d.getSeconds() <= 9 ? "0" + d.getSeconds() : d.getSeconds());
    if (date === undefined) {
      return date + " " + time;
    } else {
      return date;
    }
  }

  render() {
    return (
      <React.Fragment>
        <button
          onClick={this.handlePDFGenerate}
          type="button"
          className="btn btn-outline-primary"
          disabled={!this.props.isAuthenticated || this.props.rows.length === 0}
          style={{ color: "#a6192e" }}
        >
          Print PDF
        </button>
      </React.Fragment>
    );
  }
}
