import React, { useEffect, useState } from "react";
import { Spinner, Jumbotron } from "react-bootstrap";
import PDFTable from "./PDFTable";
import PDFButton from "./PDFButton";
import LPTable from "./LPTable";
import CSVButton from "./CSVButton";
import Filter from "./filter";
import Help from "./Help";
import Select from "react-select";
import getApplicationUrl from "./appUrl";

import { REGION_LABELS } from "../constants/regions";

const PDFSection = (props) => {
  const apiUrl = getApplicationUrl("api");
  const tableName = props.tableName.replace(" ", "");
  const columnApproved = [
    "region",
    "course",
    "duration",
    "category",
    "course_type",
    "default_frequency",
  ];
  const [errorFetchedChecker, setErrorFetchedChecker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(
    JSON.parse(sessionStorage.getItem(`${tableName}Data`)) || []
  );
  const [rows, setRows] = useState([]);
  const [filterSelection, setFilterSelection] = useState([]);
  const [columnFilterSelection, setColumnFilterSelection] = useState([]);
  const [searchFilterSelection, setSearchFilterSelection] = useState(
    JSON.parse(
      sessionStorage.getItem(`${tableName}SeachFilterSelectionData`)
    ) || ""
  );
  const [searchFilterOptions, setSearchFilterOptions] = useState(
    JSON.parse(sessionStorage.getItem(`${tableName}SeachFilterOptionsData`)) ||
      []
  );
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);

  const handleCheckboxSelect = (checkboxId) => {
    if (rows.includes(checkboxId)) {
      const theseRows = rows.filter((i) => i !== checkboxId);
      setRows(theseRows);
    } else {
      setRows(rows.concat(checkboxId));
    }
  };

  const handleMasterCheckboxSelect = (event, checkboxIds) => {
    if (event.target.checked) {
      setRows(checkboxIds);
      setMasterCheckboxChecked(true);
    } else {
      setRows([]);
      setMasterCheckboxChecked(false);
    }
  };

  const handleFilterChange = (filter, selectedOption) => {
    let selectedOptionValues;
    if (selectedOption === null || selectedOption.length === 0) {
      selectedOptionValues = [];
    } else {
      selectedOptionValues = selectedOption.map((option) => option.value);
    }
    setFilterSelection({ ...filterSelection, [filter]: selectedOptionValues });
  };

  const handleColumnFilterChange = (filter, selectedOption) => {
    let selectedOptionValues;
    if (selectedOption === null) {
      selectedOptionValues = [];
    } else {
      selectedOptionValues = selectedOption.map((option) => option.value);
    }
    setColumnFilterSelection(selectedOptionValues);
  };

  const handleSearchFilterChange = (selectedOption) => {
    setSearchFilterSelection(selectedOption);
    sessionStorage.setItem(
      `${tableName}SeachFilterSelectionData`,
      JSON.stringify(selectedOption)
    );
  };

  const getFilteredData = (data, filterSelection) => {
    let filteredData = data;
    const filterKeys = Object.keys(filterSelection);
    if (filterKeys.length !== 0) {
      for (let i = 0; i < filterKeys.length; i++) {
        let key = filterKeys[i];
        let filterArray = filterSelection[key];
        if (filterArray.length !== 0) {
          filteredData = filteredData.filter((data) =>
            filterArray.includes(data[key])
          );
        }
      }
    }
    let columnAllApproved;
    let newFilteredData = [];
    let newRow = {};
    if (columnFilterSelection.length !== 0) {
      columnAllApproved = columnApproved.concat(columnFilterSelection);
      filteredData.map(
        (row) => (
          Object.entries(row).map(function (x) {
            if (columnAllApproved.includes(x[0])) {
              let key = x[0];
              newRow[key] = x[1];
            }
            return null;
          }),
          newFilteredData.push(newRow),
          (newRow = {})
        )
      );
      return newFilteredData;
    } else {
      newFilteredData = [];
      return filteredData;
    }
  };

  const getUpdateType = () => {
    if (
      typeof props.searchFilter === "undefined" ||
      searchFilterOptions.length === 0
    ) {
      return "basic";
    } else {
      return "search";
    }
  };

  const getUrl = (updateType) => {
    if (updateType === "basic") {
      return `${apiUrl}/api/${tableName}`;
    } else {
      return `${apiUrl}/api/${tableName}/${searchFilterSelection.value}`;
    }
  };

  const getJwtToken = () => {
    let jwttoken;
    Object.keys(window.sessionStorage).forEach((key) => {
      if (key.includes("api://5982e64d-2298-44b7-bd49-01f08c1dc2df/basic")) {
        let obj = JSON.parse(window.sessionStorage.getItem(key));
        jwttoken = obj["secret"];
      }
    });
    return jwttoken;
  };

  useEffect(() => {
    if (props.isAuthenticated === true) {
      // dont do an api request
      if (
        (typeof props.searchFilter === "undefined" && data.length > 0) ||
        (typeof props.searchFilter !== "undefined" &&
          searchFilterOptions.length > 0 &&
          data.length > 0 &&
          searchFilterSelection.value === data[0][props.searchFilter])
      ) {
        let nothing;
      } else {
        let updateType = getUpdateType();
        let jwttoken = getJwtToken();
        setIsLoading(true);
        (async () => {
          await sleep(2000);
          await fetch(getUrl(updateType), {
            headers: {
              jwttoken: jwttoken,
              "Access-Control-Allow-Origin": apiUrl,
            },
          })
            .then((resp) => resp.json())
            .then(
              (d) => {
                if (updateType === "basic") {
                  setSearchFilterOptions(d);
                  sessionStorage.setItem(
                    `${tableName}SeachFilterOptionsData`,
                    JSON.stringify(d)
                  );
                } else {
                  setData(d);
                  sessionStorage.setItem(`${tableName}Data`, JSON.stringify(d));
                }
                setIsLoading(false);
              },
              (error) => {
                console.log(
                  "Error fetching data -- ",
                  error,
                  getUrl(updateType)
                );
                setErrorFetchedChecker((c) => !c);
                setIsLoading(false);
              }
            );
        })();
      }
    }
  }, [
    errorFetchedChecker,
    tableName,
    apiUrl,
    props.isAuthenticated,
    data.length,
    searchFilterSelection,
    props.searchFilter,
  ]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getUnique = (d, filter) => {
    const unique = d
      .map((item) => item[filter])
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();
    const uniqueSelectFormatted = formatFilterOptions(unique);
    return uniqueSelectFormatted;
  };

  const formatFilterOptions = (arrayOfString, isSearchFilter = false) => {
    const uniqueSelectFormatted = arrayOfString.map((v) => {
      const label = isSearchFilter ? REGION_LABELS[v] || v : v;
      return {
        label: label,
        value: v,
      };
    });
    return uniqueSelectFormatted;
  };

  const getColumnFilter = (data, filter) => {
    if (data === undefined || data.length === 0) {
    } else {
      filter = Array.from(new Set(Object.keys(data[0])));
      return filter
        .filter((v) => !columnApproved.includes(v))
        .map((v) => ({
          label: v,
          value: v,
        }));
    }
    return getUnique(data, filter);
  };

  const capitalize = (word) => {
    if (typeof word === "string") {
      word = word
        .split("_")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
    return word;
  };

  const getAllColumns = () => {
    let allColumns = [...props.filters];
    allColumns.unshift(props.searchFilter);
    return [...new Set(allColumns)];
  };

  const DisplayPDFTable = () => {
    return (
      <PDFTable
        key={tableName}
        data={getFilteredData(data, filterSelection)}
        rows={rows}
        cols={getAllColumns()}
        filterSelection={filterSelection}
        masterCheckboxChecked={masterCheckboxChecked}
        handleCheckboxSelect={handleCheckboxSelect}
        handleMasterCheckboxSelect={handleMasterCheckboxSelect}
        filterRows={filterRows.length}
      />
    );
  };

  const DisplayTable = () => {
    if (props.isAuthenticated !== true) {
      return (
        <Jumbotron>
          <h1>Hello, there!</h1>
          <p>Please sign in to access data</p>
        </Jumbotron>
      );
    }
    if (props.isAuthorized !== true) {
      return (
        <Jumbotron>
          <h1>Incorrect group assignment</h1>
          <p>
            Please contact your local administrator and ask to be assigned to a
            group
          </p>
        </Jumbotron>
      );
    }
    if (typeof props.searchFilter === "undefined") {
      if (data.length > 0) {
        if (props.tableType === "csv") {
          return (
            <LPTable
              key={"LearningPlans"}
              lpData={getFilteredData(data, filterSelection)}
              filterRows={filterRows.length}
            />
          );
        } else {
          return <DisplayPDFTable />;
        }
      } else {
        if (!isLoading) {
          return (
            <Jumbotron>
              <h1>No results found for your search</h1>
              <p>
                Please try a different search term or adjust your filters to
                broaden the search criteria.
              </p>
            </Jumbotron>
          );
        } else {
          return (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          );
        }
      }
    }
    if (typeof props.searchFilter !== "undefined") {
      if (searchFilterSelection.length === 0) {
        const searchFilter =
          props.searchFilter.toLowerCase() === "district"
            ? "Region"
            : capitalize(props.searchFilter);
        return (
          <Jumbotron>
            <h1>Please select a value using the "{searchFilter}" filter</h1>
            <p>Your data will display here.</p>
          </Jumbotron>
        );
      } else {
        var specificSearchFilterSelection;
        for (let i = 0; i < data.length; i++) {
          if (data[i][props.searchFilter] !== "All") {
            specificSearchFilterSelection = data[i][props.searchFilter];
            break;
          }
        }
        if (searchFilterSelection.value !== specificSearchFilterSelection) {
          if (!isLoading) {
            return (
              <Jumbotron>
                <h1>No results found for your search</h1>
                <p>
                  Please try a different search term or adjust your filters to
                  broaden the search criteria.
                </p>
              </Jumbotron>
            );
          } else {
            return (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            );
          }
        } else {
          if (props.tableType === "csv") {
            return (
              <LPTable
                key={"LearningPlans"}
                lpData={getFilteredData(data, filterSelection)}
                filterRows={filterRows.length}
              />
            );
          } else {
            return <DisplayPDFTable />;
          }
        }
      }
    }
  };

  const DisplaySearchFilter = () => {
    if (typeof props.searchFilter !== "undefined") {
      const placeholder =
        props.searchFilter.toLowerCase() === "district"
          ? "Region"
          : capitalize(props.searchFilter);
      return (
        <div className="col">
          <Select
            closeMenuOnSelect={false}
            placeholder={placeholder}
            value={searchFilterSelection}
            options={formatFilterOptions(searchFilterOptions, true)}
            onChange={(event) => handleSearchFilterChange(event)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const DisplayDefaultFilter = (filter) => {
    return (
      <Filter
        title={capitalize(filter)}
        key={`filter_${filter}`}
        options={getUnique(getFilteredData(data, filterSelection), filter)}
        filter={filter}
        onChange={handleFilterChange}
      />
    );
  };

  const DisplayButton = () => {
    if (props.tableType === "csv") {
      return (
        <CSVButton
          key="pdf_button"
          data={getFilteredData(data, filterSelection)}
          isAuthenticated={props.isAuthenticated}
        />
      );
    } else {
      return (
        <PDFButton
          key="pdf_button"
          rows={rows}
          data={getFilteredData(data, filterSelection)}
          pdf={tableName}
          isAuthenticated={props.isAuthenticated}
        />
      );
    }
  };

  const getFilterRows = (filters, size) => {
    let allFilters = [...filters];
    if (filters.length <= size + 1) {
      return [allFilters];
    }
    allFilters.unshift("Dummy");
    const result = new Array(Math.ceil(allFilters.length / size))
      .fill()
      .map((_) => allFilters.splice(0, size));
    result[0].shift();
    return result;
  };

  const filterRows = getFilterRows(props.filters, 3);

  const DisplayHelp = () => {
    if (props.help === true) {
      return <Help tableName={props.tableName} />;
    } else {
      return null;
    }
  };

  return (
    <div className="container-fluid p-5 pb-0">
      <div className="row pb-4">
        <div className="col-2" />
        <div className="col">
          <h1>{props.tableName.toUpperCase()}</h1>
        </div>
        <div className="col-2">
          <DisplayButton />
          <DisplayHelp />
        </div>
      </div>
      {filterRows.map((filterRow, index) => {
        if (index === 0) {
          return (
            <div className="row pb-4" key={`filter_row_${index}`}>
              <DisplaySearchFilter />
              {filterRow.map((filter) => DisplayDefaultFilter(filter))}
            </div>
          );
        } else {
          return (
            <div className="row pb-4" key={`filter_row_${index}`}>
              {filterRow.map((filter) => DisplayDefaultFilter(filter))}
              {props.columnFilters.map((filter) => (
                <Filter
                  title={capitalize(filter)}
                  key={`filter_${filter}`}
                  options={getColumnFilter(data, filter)}
                  filter={filter}
                  onChange={handleColumnFilterChange}
                />
              ))}
            </div>
          );
        }
      })}
      <DisplayTable />
    </div>
  );
};

export default PDFSection;
