import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Navigation from "./components/navigation";
import TableSection from "./components/TableSection";
import Home from "./components/home";
import withAuthProvider from "./components/AuthProvider";
import Testing from "./components/Testing";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Navigation
            pages={["Course Descriptions", "Certificates", "Learning Plans"]}
            isAuthenticated={this.props.isAuthenticated}
            authButtonMethod={
              this.props.isAuthenticated ? this.props.logout : this.props.login
            }
            user={this.props.user}
          />
          <Switch>
            <Route
              path="/course-descriptions"
              component={(props) => (
                <TableSection
                  isAuthenticated={this.props.isAuthenticated}
                  isAuthorized={this.props.isAuthorized}
                  filters={["Category", "Name", "Type"]}
                  columnFilters={[]}
                  searchFilter={"Course Catalog"}
                  tableName={"course descriptions"}
                />
              )}
            />
            <Route
              path="/certificates"
              component={(props) => (
                <TableSection
                  isAuthenticated={this.props.isAuthenticated}
                  isAuthorized={this.props.isAuthorized}
                  filters={["Pay Type", "Name", "Category", "Course", "Year"]}
                  columnFilters={[]}
                  searchFilter={"Job"}
                  tableName={"certificates"}
                />
              )}
            />
            <Route
              path="/learning-plans"
              component={(props) => (
                <TableSection
                  isAuthenticated={this.props.isAuthenticated}
                  isAuthorized={this.props.isAuthorized}
                  filters={[
                    "course",
                    "duration",
                    "category",
                    "course_type",
                    "default_freq",
                  ]}
                  columnFilters={["learning_plan"]}
                  tableName={"Learning Plans"}
                  searchFilter={"district"}
                  help={true}
                  tableType={"csv"}
                />
              )}
            ></Route>
            <Route path="/testing" exact>
              <Testing />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withAuthProvider(App);
