import React from "react";
import "../tableHeaders.css";
import vHeight from "../utils/vHeight";
import { REGION_LABELS } from "../constants/regions";

const nonLps = [
  "region",
  "course",
  "duration",
  "category",
  "course_type",
  "default_frequency",
];

const getNonLps = (data) => {
  var newData = {};
  nonLps.forEach((nonLp) => {
    newData[nonLp] = data[nonLp];
  });
  return newData;
};

const getLps = (data) => {
  const {
    district,
    course,
    duration,
    category,
    course_type,
    default_frequency,
    ...lps
  } = data;
  return lps;
};

const LPTable = (props) => {
  const lpData =
    !props.lpData || !Array.isArray(props.lpData)
      ? []
      : props.lpData.map((row) => {
          const district = row.district;
          const region = REGION_LABELS[district] || district;
          return { ...row, region };
        });

  const learningPlans =
    props.lpData[0] === undefined
      ? []
      : Object.keys(props.lpData[0]).filter((v) => !nonLps.includes(v));

  return (
    <div
      className="row"
      style={{ overflowY: "auto", height: vHeight(props.filterRows) }}
    >
      <table className="table table-striped table-hover table-bordered">
        <thead
          className="thead-dark membership-tiers text-left"
          style={{ position: "sticky", top: 0 }}
        >
          <tr>
            <th
              key="District"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Region
            </th>
            <th
              key="CourseName"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Course Name
            </th>
            <th
              key="Duration"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Dur (Hrs)
            </th>
            <th
              key="Category"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Category
            </th>
            <th
              key="CourseType"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Course Type
            </th>
            <th
              key="DefaultFrequency"
              scope="col"
              style={{ position: "sticky", top: 0 }}
            >
              Default Frequency
            </th>
            {learningPlans.map((learningPlan) => (
              <th
                key={learningPlan}
                className="rotated-text"
                scope="col"
                style={{ position: "sticky", top: 0 }}
              >
                <div>
                  <span>{learningPlan}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lpData.map((row) => (
            <tr key={row.district + row.course}>
              {Object.keys(getNonLps(row)).map((rowCol) => (
                <td key={rowCol}>{row[rowCol]}</td>
              ))}
              {Object.keys(getLps(row)).map((rowCol) => (
                <td key={rowCol}>{row[rowCol]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LPTable;
