export const REGION_LABELS = {
  100: "100 CORP",
  200: "200 MATL",
  250: "250 MDSO",
  300: "300 RMTN",
  350: "350 WATR",
  400: "400 SCAL",
  500: "500 NCAL",
  590: "590 SWST",
  600: "600 PCFC",
  650: "650 PNWT",
  700: "700 SCTL",
  710: "710 SVCS",
  800: "800 SEST",
  900: "900 DEVT",
};
