import React from "react";

const PDFTableRow = props => {
  return (
    <React.Fragment>
      <tr key={props.data.id}>
        <td>
          <input
            type="checkbox"
            checked={(props.rows.includes(props.data.id) ? true : false)}
            onChange={() => props.onCheckboxSelect(props.data.id)}
          />
        </td>
        {props.dataCols.map(col => (
          <td key={col}>{props.data[col]}</td>
        ))}
      </tr>
    </React.Fragment>
  );
}

export default PDFTableRow;