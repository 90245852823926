import React from "react";
import download from "downloadjs";
import getApplicationUrl from "./appUrl";
import { REGION_LABELS } from "../constants/regions";

const postCSV = (csvData) => {
  const apiUrl = getApplicationUrl("api");
  let restructuredData = restructureData(csvData);
  let jwttoken;
  Object.keys(window.sessionStorage).forEach((key) => {
    if (key.includes("api://5982e64d-2298-44b7-bd49-01f08c1dc2df/basic")) {
      let obj = JSON.parse(window.sessionStorage.getItem(key));
      jwttoken = obj["secret"];
    }
  });
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      jwttoken: jwttoken,
      "Access-Control-Allow-Origin": apiUrl,
    },
    body: JSON.stringify({ csvData: restructuredData }),
  };
  fetch(`${apiUrl}/api/LPCSV/`, requestOptions)
    .then(async (response) => {
      const data = await response;
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      return response.blob();
    })
    .then((blob) => {
      download(blob, "LearningPlans.xlsx");
    })
    .catch((error) => {
      console.error("Something went wrong making a CSV!", error);
    });
};

const restructureData = (data) => {
  const csvData = data.map((row) => {
    const { district, ...rest } = row;
    const region = REGION_LABELS[district] || district;
    return { ...rest, region };
  });

  let columns = {};
  for (const key in csvData[0]) {
    let cleanKey = key
      .split("_")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    columns[cleanKey] = [];
  }
  for (const rowIndex in csvData) {
    let row = csvData[rowIndex];
    for (const [key, value] of Object.entries(row)) {
      let cleanKey = key
        .split("_")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      columns[cleanKey].push(value);
    }
  }
  return columns;
};

const CSVButton = (props) => {
  const csvData = props.data.length > 0 ? props.data : [];
  return (
    <button
      type="button"
      onClick={() => postCSV(csvData)}
      className="btn btn-outline-primary"
      disabled={!props.isAuthenticated || csvData.length === 0}
      style={{ color: "#a6192e", marginRight: "1rem" }}
    >
      Print CSV
    </button>
  );
};

export default CSVButton;
