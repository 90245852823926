import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import AuthButton from "./authButton";

const Navigation = (props) => {
  return (
    <Navbar>
      <Navbar.Brand>
        <img
          alt=""
          src={require("../assets/logos/hpLogo.png")}
          height="35px"
          className="d-none d-sm-none d-md-none d-lg-inline align-top"
        />
        {"    "}
      </Navbar.Brand>
      <Navbar.Brand as={Link} to="/" className="mr-auto">
        <img
          alt=""
          src={require("../assets/logos/logo.png")}
          height="30px"
          className="d-inline-block align-top"
        />
        {"    "}
      </Navbar.Brand>
      <Nav className="d-none d-sm-none d-md-none d-lg-inline">
        {props.pages.map((page) => (
          <Nav.Link
            style={{
              fontSize: ".875rem",
              textDecoration: "underline",
              fontWeight: "bold",
              marginRight: "1rem",
              fontFamily: "Helvetica LT std light",
              display: "inline",
            }}
            key={page}
            as={Link}
            to={{ pathname: `/${page}`.toLowerCase().replace(" ", "-") }}
          >
            {page}
          </Nav.Link>
        ))}
      </Nav>
      <AuthButton
        isAuthenticated={props.isAuthenticated}
        authButtonMethod={props.authButtonMethod}
        user={props.user}
      />
    </Navbar>
  );
};

export default Navigation;
