import React from "react";
import PDFTableRow from "./PDFTableRow";
import vHeight from "../utils/vHeight";

const PDFTable = props => {
  return (
    <div style={{ overflowY: "auto", height: vHeight(props.filterRows) }}>
      <table className="table table-striped table-hover table-bordered">
        <thead className="thead-dark">
          <tr>
            <th scope="col" style={{position: "sticky", top: 0}}>
              <input
                type="checkbox"
                onChange={(e) => { props.handleMasterCheckboxSelect(e, props.data.map(datum => datum.id)) }}
                checked={props.masterCheckboxChecked}
              />
            </th>
            {props.cols.map(col => (
              <th key={col} scope="col" style={{position: "sticky", top: 0}}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((data) => (
            <PDFTableRow
              key={data.id}
              data={data}
              rows={props.rows}
              dataCols={props.cols}
              onCheckboxSelect={props.handleCheckboxSelect}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PDFTable