import React from "react";
import {
  DropdownButton,
  Dropdown,
  NavItem,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";

function UserAvatar(props) {
  // If a user avatar is available, return an img tag with the pic
  if (props.user.avatar) {
    return (
      <img
        src={props.user.avatar}
        alt="user"
        className="rounded-circle align-self-center mr-2"
      ></img>
    );
  }

  // // No avatar available, return a default icon
  return (
    <span style={{ color: "white" }}>
      <i
        className="far fa-user-circle fa-2x rounded-circle align-self-center mr-2"
      ></i>
    </span>
  );
}

function AuthNavItem(props) {
  // If authenticated, return a dropdown with the user's info and a
  // sign out button
  if (props.isAuthenticated) {
    return (
      <DropdownButton
        as={ButtonGroup}
        key="ksjdfk"
        id="LDKFKDLFKLDKFL"
        variant="Success"
        title={<UserAvatar user={props.user} />}
        alignRight={true}
      >
        <Dropdown.Item eventKey="1">{props.user.displayName}</Dropdown.Item>
        <Dropdown.Item eventKey="2">{props.user.email}</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={props.authButtonMethod} eventKey="4">
          Sign Out
        </Dropdown.Item>
      </DropdownButton>
    );
  }
  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <Button
        onClick={props.authButtonMethod}
        className="btn btn-sm"
        style={{
          border: "none",
          "&:hover": { backgroundColor: "pink !important" },
        }}
      >
        Sign In
      </Button>
    </NavItem>
  );
}

const AuthButton = (props) => {
  return (
    <AuthNavItem
      isAuthenticated={props.isAuthenticated}
      authButtonMethod={props.authButtonMethod}
      user={props.user}
    />
  );
};

export default AuthButton;
