import React, { useState } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";

const Help = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="info" onClick={handleShow}>
        <i className="fas fa-question" />
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Learning Plan Legend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Learning plans will look different between this web view and the
            view in excel.
          </p>
          <ul>
            <li>
              <p>
                <Badge variant="primary">Blue</Badge> items are regular courses,
                designated here as courses from 0-99
              </p>
            </li>
            <li>
              <p>
                <Badge variant="success">Green </Badge> items are first year
                courses, designated here as courses from 100-199
              </p>
            </li>
            <li>
              <p>
                <Badge variant="warning">Yellow</Badge> items are as needed
                courses, designated here as courses from 200-299
              </p>
            </li>
            <li>
              <p>
                Courses that end in a <Badge variant="secondary">Zero</Badge>{" "}
                only need to be completed once
              </p>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Help;
